import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import Dashboard from "./../dashboard/Dashboard";
import Login from "./../login/Login";
import { ToastContainer } from "react-toastify";

import UserList from "../user/userList";
import EditUser from "../user/editUser";
import CompetentMasterList from "../competentMaster/competentMasterList";
import EditCompetentMaster from "../competentMaster/editCompetentMaster";
import ProductsList from "../products/productsList";
import EditProduct from "../products/editProduct";
import ShopsList from "../shops/shopsList";
import EditShop from "../shops/editShop";
import ClientsList from "../clients/clientsList";
import EditClient from "../clients/editClient";
import TPIformTemplateList from "../TPIformTemplate/TPIformTemplateList";
import EditTPIformTemplate from "../TPIformTemplate/editTPIformTemplate";
import DisplayTemplate from "../allTemplateForms/displayTemplate";
import EditForms from "../allForms/editForms";
import FormReportsList from "../allForms/formReportsList";
import FormPrint from "../allForms/formPrint";
import SummaryList from "../summaryReport/summaryList";
import MultiplePrintForm from "../allForms/multiplePrintForm";
import Sticker from "../allForms/sticker";
import MultipleStickers from "../allForms/multipleStickers";

export default function Main(props) {
  const [title, setTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn")
  );

  let PropsMode = props.mode;
  let PropsSetMode = props.setMode;
  let PropsSetThemecolor = props.setThemecolor;

  const routes = [
    {
      path: "/",
      element: sessionStorage.getItem("isLoggedIn") ? (
        <Dashboard />
      ) : (
        <Login setIsLoggedIn={setIsLoggedIn} setLoader={props.setLoader} />
      ),
    },

    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      path: "/competentMasterList",
      element: <CompetentMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/editCompetentMaster",
      element: <EditCompetentMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editCompetentMaster/:id",
      element: <EditCompetentMaster setLoader={props.setLoader} />,
    },

    {
      path: "/productsList",
      element: <ProductsList setLoader={props.setLoader} />,
    },
    {
      path: "/editProduct",
      element: <EditProduct setLoader={props.setLoader} />,
    },
    {
      path: "/editProduct/:id",
      element: <EditProduct setLoader={props.setLoader} />,
    },

    {
      path: "/shopsList",
      element: <ShopsList setLoader={props.setLoader} />,
    },
    {
      path: "/editShop",
      element: <EditShop setLoader={props.setLoader} />,
    },
    {
      path: "/editShop/:id",
      element: <EditShop setLoader={props.setLoader} />,
    },

    {
      path: "/clientsList",
      element: <ClientsList setLoader={props.setLoader} />,
    },
    {
      path: "/editClient",
      element: <EditClient setLoader={props.setLoader} />,
    },
    {
      path: "/editClient/:id",
      element: <EditClient setLoader={props.setLoader} />,
    },

    {
      path: "/templateList",
      element: <TPIformTemplateList setLoader={props.setLoader} />,
    },

    {
      path: "/editTemplate/:templatesId",
      element: <EditTPIformTemplate setLoader={props.setLoader} />,
    },

    {
      path: "/formReportsList",
      element: <FormReportsList setLoader={props.setLoader} />,
    },
    
    {
      path: "/sticker/:reportID",
      element: <Sticker setLoader={props.setLoader} />,
    },

    {
      path: "/multipleStickers",
      element: <MultipleStickers setLoader={props.setLoader} />,
    },

    {
      path: "/displayTemplate/:reportID/:templateID/:isPrint?",
      element: <DisplayTemplate setLoader={props.setLoader} />,
    },

    {
      path: "/usersList",
      element: <UserList setLoader={props.setLoader} />,
    },
    {
      path: "/editUser",
      element: <EditUser setLoader={props.setLoader} />,
    },
    {
      path: "/editUser/:id",
      element: <EditUser setLoader={props.setLoader} />,
    },

   
    {
      path: "/editForms",
      element: <EditForms setLoader={props.setLoader} />,
    },
    {
      path: "/editForms/:reportID/:templateID",
      element: <EditForms setLoader={props.setLoader} />,
    },
    {
      path: "/printForm/:reportID/:templateID",
      element: <FormPrint setLoader={props.setLoader} />,
    },
    {
      path: "/printForm/:reportID/:templateID/print",
      element: <FormPrint setLoader={props.setLoader} />,
    },
    {
      path: "/printForm",
      element: <FormPrint setLoader={props.setLoader} />,
    },
    
    {
      path: "/summaryList",
      element: <SummaryList setLoader={props.setLoader} />,
    },

    {
      path: "/multiplePrintForm",
      element: <MultiplePrintForm setLoader={props.setLoader} />,
    },

  ];

  useEffect(() => {
    const authToken = sessionStorage.getItem("isLoggedIn");
    const currentRoute = window.location.hash || "#/"; // Default to "#/" if empty
    if (!authToken && currentRoute !== "#/" && currentRoute !== "") {
      window.location.href = "/"; 
    }
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <HashRouter>
        {sessionStorage.getItem("isLoggedIn") && (
          <Navbar
            setThemecolor={PropsSetThemecolor}
            mode={PropsMode}
            setMode={PropsSetMode}
            title={title}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}

        <Box
          component="main"
          sx={{ flexGrow: 1, pt: 9, width: "100%", height: "100%" }}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
