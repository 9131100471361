import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Toolbar,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL, DOMAIN } from "../../global";
import moment from "moment";
import axios from "axios";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import headerImage from "../image/visibleSafety/VSS_LETTER_HEAD-removebg.png";
import footerImage from "../image/visibleSafety/footer_VCC1.png";
import vssLogo from "../image/visibleSafety/VSS_LOGO-removebg-preview.png";
import vssStamp from "../image/visibleSafety/competent stamp.png";
import haryanaStamp from "../image/visibleSafety/COMPETENT PERSON HARYANA.png";
import rajasthanStamp from "../image/visibleSafety/COMPETENT PERSON RAJASTHAN STAMP.png";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import QRCode from "react-qr-code";

const FormPrint = ({ selectedFormID, selectedTemplateID }) => {
  const location = useLocation();
  const [ids, setIds] = React.useState([]);
  const [allIds, setAllIds] = useState([]);
  const [pdfGenerated, setpdfGenerated] = React.useState(false);
  const [downloadingCount, setDownloadingCount] = useState(0);

  const [inputStates, setInputStates] = React.useState({});
  const [inputFields, setInputFields] = React.useState([]);
  const [filteredInputFields, setfilteredInputFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { reportID, templateID } = useParams();
  const [totalReportCount, setTotalReportCount] = React.useState(0);
  const [newReportID, setNewReportID] = React.useState(
    reportID || selectedFormID
  );
  const [newTemplateID, setNewTemplateID] = React.useState(
    templateID || selectedTemplateID
  );
  const [printing, setPrinting] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({});
  const [competentPerson, setCompetentPerson] = React.useState({});
  const [Factory, setFactory] = React.useState([]);
  const [Shop, setShop] = React.useState([]);
  const [Product, setProduct] = React.useState([]);
  const currentRoute = window.location.href;
  const routeParts = currentRoute.split("/");
  const lastSegment = routeParts[routeParts.length - 1];
  const printComponentRef = useRef(null);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const [stamLogo, setStamLogo] = React.useState(vssStamp);
  const [certificateNo, setCertificateNo] = React.useState("");
  const [approvedBy, setApprovedBy] = React.useState(null);
  const userType = localStorage.getItem("type");
  const userId = localStorage.getItem("id");

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];
      setApprovedBy(data?.approvedBy);
      setCertificateNo(data?.reportNo);

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

 

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          setFormInfo(data);
          axios.get(data?.url).then((respose) => {
            setInputFields(respose.data);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const fetchCompetents = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `competents/${id}`)
        .then((res) => {
          let data = res.data?.[0];
          setCompetentPerson(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist Competents: ", e);
    }
  };

  const fetchFactory = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `clients`)
        .then((res) => {
          let data = res.data;
          setFactory(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Factory : ", e);
    }
  };

  const fetchShop = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `shops`)
        .then((res) => {
          let data = res.data;
          setShop(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Shop : ", e);
    }
  };

  const fetchProduct = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `products`)
        .then((res) => {
          let data = res.data;
          setProduct(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Product : ", e);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    switch (formInfo?.branchId) {
      case 15:
        setStamLogo(rajasthanStamp);
        break;
      case 16:
        setStamLogo(haryanaStamp);
        break;
      default:
        setStamLogo(vssStamp);
    }
  }, [formInfo?.branchId]);

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      if (lastSegment !== "print") {
        window.location.reload();
      }
    }
  };

  const generateDraftImage = async () => {
    let htmlString = `
    <div>
 <img src=${vssLogo} style="width:70%; height:600px; opacity:0.2"/>
        
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadHeader = async () => {
    console.log("headerImage", headerImage);
    let htmlString = `
    <div>
      <img src=${headerImage} style="width:100%; height:130px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
       <img src=${footerImage} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [60, 1, 40, 1],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true, 
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();

      const watermarkImage = await generateDraftImage();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // Add watermark image at the back of the page
        const pageWidth1 = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = 400;
        const imgHeight =
          (imgWidth * watermarkImage.height) / watermarkImage.width;
        const x1 = (pageWidth1 - imgWidth) / 2;
        const y1 = (pageHeight - imgHeight) / 2;
        pdf.addImage(watermarkImage.src, "PNG", x1, y1, imgWidth, imgHeight);

        // border add for contnet
        // const borderWidth = 0.6;
        // const leftMargin = 15;
        // const borderX = leftMargin;
        // const borderY = 5;
        // const rightMargin = 595 - leftMargin * 0.95;
        // pdf.setLineWidth(borderWidth);
        // pdf.rect(borderX, borderY, rightMargin - leftMargin, 750);

        let image = await generateLetterHeadHeader();
        pdf.addImage(image, 0, 0, 595, 60);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 800, 595, 45);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `form.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  const renderStaticSection = () => {
    return (
      <>
        <TableRow>
          <TableCell align="center" sx={{ border: "none" }} colSpan={3}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              {certificateNo && (
                <h6>
                  <i>
                    <b
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "14px",
                      }}
                    >
                      Certificate No.-{certificateNo || ""}{" "}
                    </b>
                  </i>
                </h6>
              )}
              {formInfo?.branchId == 15 && (
                <div style={{ display: "flex" }}>
                  <h6>
                    <i>
                      <b
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "14px",
                        }}
                      >
                        Competency No:- CMP-618/2023 ,{" "}
                      </b>
                    </i>
                  </h6>
                  <h6>
                    <i>
                      {" "}
                      <b
                        style={{
                          paddingLeft: "10px",
                          fontFamily: "Times New Roman",
                          fontSize: "14px",
                        }}
                      >
                        Dated:08/01/2024 Valid up to 31/12/2024
                      </b>
                    </i>
                  </h6>
                </div>
              )}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="center"
            style={{ border: "none", padding: "0px" }}
            colSpan={3}
          >
            <h6
              style={{
                width: "60%",
                margin: "auto",
                // marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <i>
                <b>
                  <u>{formInfo?.name}</u>
                </b>
              </i>
            </h6>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderDynamicTable = () => {
    return (
      <>
        {filteredInputFields?.map((field, index) => (
          <TableRow
            key={index}
            style={{ pageBreakInside: "avoid", padding: "0px" }}
          >
            <TableCell style={{ width: "50%", border: "none", padding: "1px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "10.8px",
                  textAlign: "left",
                  paddingRight: "5px",
                  fontFamily: "Times New Roman",
                }}
              >
                <i>
                  <b>{field?.label}</b>
                </i>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "1%",
                textAlign: "center",
                border: "none",
                padding: "1px",
              }}
            >
              :
            </TableCell>
            <TableCell style={{ width: "49%", border: "none", padding: "1px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "5px",
                  fontSize: "10.8px",
                  fontFamily: "Times New Roman",
                }}
              >
                <i>
                  <b>
                    {field?.stateKey === "factoryId"
                      ? Factory?.filter(
                          (ele) => ele.id === inputStates?.[field?.stateKey]
                        )?.[0]?.name
                      : field?.stateKey === "shopId"
                      ? Shop?.filter(
                          (ele) => ele.id === inputStates?.[field?.stateKey]
                        )?.[0]?.name
                      : field?.stateKey === "equipmentId"
                      ? Product?.filter(
                          (ele) => ele.id === inputStates?.[field?.stateKey]
                        )?.[0]?.name
                      : field?.type === "DatePicker"
                      ? new Date(
                          inputStates?.[field?.stateKey]
                        ).toLocaleDateString("en-GB")
                      : inputStates?.[field?.stateKey]}
                  </b>
                </i>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderSignatureSection = () => {
    function getFormattedDate() {
      const today = inputStates?.inspectedOn
        ? new Date(inputStates?.inspectedOn)
        : new Date();
      const formattedDate = today.toLocaleDateString("en-GB");
      return formattedDate;
    }

    function getNextYearDate() {
      const today = inputStates?.reportDate
        ? new Date(inputStates?.reportDate)
        : new Date();
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);
      const formattedNextYearDate = inputStates?.certificateValidity
        ? moment(inputStates?.certificateValidity).format("DD/MM/YYYY")
        : nextYear.toLocaleDateString("en-GB");

      return formattedNextYearDate;
    }

    return (
      <div>
        <div
          style={{
            textAlign: "left",
            fontSize: "0.6rem",
            fontFamily: "Times New Roman",
          }}
        >
          <h6>
            <i>
              <b>Inspected on: {getFormattedDate()}</b>{" "}
              {inputStates["conclusionOfCertificate"]}
            </i>
          </h6>
          <h6>
            {" "}
            <i>
              {" "}
              <b>Next inspection due on:{getNextYearDate()}</b>
            </i>
          </h6>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pageBreakInside: "avoid",
            marginTop: "5px",
          }}
        >
          <div>
            <QRCode
              value={`${DOMAIN}/#/printForm/${newReportID}/${newTemplateID}`}
              align="center"
              size={60}
            />
          </div>

          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              // margin: "130px 0px",
            }}
          >
            <div>
              <img style={{ height: "100px" }} src={stamLogo} />
            </div>
            <div>
              <i>
                {" "}
                <h6 style={{ fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <b>{competentPerson?.personName}</b>
                </h6>{" "}
              </i>
              <i>
                {" "}
                <h6 style={{ fontSize: "16px", fontFamily: "Times New Roman" }}>
                  <b>Competent Person</b>
                </h6>{" "}
              </i>
              <i>
                {" "}
                <h6 style={{ fontSize: "16px", fontFamily: "Times New Roman" }}>
                  <b>{competentPerson?.qualification}</b>
                </h6>{" "}
              </i>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function filterInputFields(inputFields, stateKeyArray) {
    return inputFields.filter(
      (field) => !stateKeyArray.includes(field.stateKey)
    );
  }

  useEffect(() => {
    const stateKeyArray = [
      "competentPerson",
      "reportNumber",
      "reportDate",
      "factoryId",
      "shopId",
      "equipmentId",
      "totalTest",
      "capacity",
      "renewalInformation ",
      "inspectionDate ",
      "certificateValidity ",
      "distinctiveNumber",
      "inspectionDate",
      "utrasonicTestDate",
      "hydraulicTestDate",
      "internalTestDate",
      "internalExaminationDueDate",
      "certificateValidityDate",
      "certificateValidity",
      "certificateNumber",
      "conclusionOfCertificate",
      "inspectedOn",
    ];
    const filteredInputFields = filterInputFields(inputFields, stateKeyArray);
    setfilteredInputFields(filteredInputFields);
  }, [inputFields]);

  const handleApprove = () => {
    try {
      axiosWithToken
        .patch(BASE_URL + `reportValues/${newReportID}`,{approvedBy:userId})
        .then((res) => {
          refresh()
        })
        
    } catch (e) {
      console.log("Failed to fetch  Product : ", e);
    }
  };

  useEffect(() => {
    if (lastSegment === "print") {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (newTemplateID > 0) {
      fetchTemplate(newTemplateID);
    }
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID, newTemplateID]);

  useEffect(() => {
    if (inputStates?.competentPerson)
      fetchCompetents(inputStates?.competentPerson);
  }, [inputStates?.competentPerson]);

  useEffect(() => {
    fetchProduct();
    fetchShop();
    fetchFactory();
  }, []);

  const fetchFormData = () => {
    try {
      let SQLQuery = {
        query: `SELECT id, templateId FROM reportValues WHERE id IN (${ids.join(
          ","
        )}) AND approvedBy IS NOT NULL ORDER BY id DESC`,
      };
      

      axiosWithToken.post(BASE_URL + `dynamic`, SQLQuery).then((res) => {
        let data = res.data;
        const formattedData = data?.map(item => `${item.id},${item.templateId}`);
        const [repoId, tempId] = formattedData[0]?.split(",") || [];
        
        setNewReportID(repoId);
        setNewTemplateID(tempId);
        setAllIds(formattedData);
      });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

 useEffect(() => {
    if (ids?.length > 0) fetchFormData();
  }, [ids]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);


  useEffect(() => {


    if ( pdfGenerated && allIds.length > 1 && downloadingCount < allIds.length) {
     const timer = setTimeout(() => {
        generatePDF().then(() => {
          
         if (downloadingCount+1 < allIds.length) {
          const [repoId, tempId] = allIds[downloadingCount+1]?.split(",") || [];
          setNewReportID(repoId);
          setNewTemplateID(tempId);
         
          }
          setDownloadingCount((prev) => prev + 1);
          // pdfGenerated = false;
        });
      }, 1000);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [allIds,certificateNo,  pdfGenerated]);

  return (
    <>
      {newReportID && (
        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ marginRight: "20px" }}
            // onClick={generatePDF}
            onClick={() => {
              if(allIds.length > 1) {                      
                setpdfGenerated(true);
              }else{
              generatePDF();
             
              }
            }}
            disabled={!approvedBy}
          >
            Print Report
          </Button>
        </Toolbar>
      )}
      <div
        id="formToPrint"
        ref={printComponentRef}
        style={{
          width: "100%",
          fontSize: "14px",
          margin: "auto",
          position: "relative",
          fontFamily: "Times New Roman",
        }}
      >
        <div style={{ width: "100%" }} id="header-section"></div>
        <div
          style={{
            width: "96%",
            fontSize: "14px",
            fontFamily: "Times New Roman",
            margin: "auto",
            // padding: "7px",
            position: "relative",
          }}
        >
          <Table>
            <TableBody>
              {renderStaticSection()}
              {renderDynamicTable()}
            </TableBody>
          </Table>
          {renderSignatureSection()}
        </div>

        {downloadPdfloading && (
          <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
        )}
      </div>
      {userType == 1 && approvedBy === null && (
        <Button
          variant="contained"
          size="small"
          sx={{
            marginRight: "20px",
            backgroundColor: "green",
            color: "white",
            alignSelf: "flex-start", // Aligns button to the left side in flex containers
          }}
          onClick={handleApprove}
        >
          Approve
        </Button>
      )}
    </>
  );
};

export default FormPrint;
